import React, { FC, useState } from 'react';
import Dropdown from './Dropdown';
import { LanguageItem } from './items/Languages';

type LanguageItemProps = React.ComponentProps<typeof LanguageItem>;

type Props = {
  current: LanguageItemProps;
  currentLabel: string;
};

const Item: FC<LanguageItemProps> = (props) => (
  <Dropdown.Item selected={false}>
    <LanguageItem {...props} />
  </Dropdown.Item>
);

const LanguagesDropdown: FC<Props> & { Item: FC<LanguageItemProps> } = ({
  current,
  children,
  currentLabel,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown {...props}>
      <div className="LanguageDropdown__container">
        <Dropdown.SelectedItem
          sign={false}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={currentLabel}
        >
          <LanguageItem Icon={current.Icon} name={current.name} />
        </Dropdown.SelectedItem>
      </div>
      <Dropdown.List isOpen={isOpen} className="LanguageDropdown__container">
        {children}
      </Dropdown.List>
    </Dropdown>
  );
};

LanguagesDropdown.Item = Item;

export default LanguagesDropdown;
