import React, {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import cx from 'classnames';
import CloseIcon from '../../atoms/Icons/Close';
import Button from '../../atoms/Button/Button';
import { ButtonTypes } from '../../atoms/Button/Button.types';
import BurgerMenuIcon from '../../atoms/Icons/BurgerMenu';
import { useNavigationStatus } from '../../hooks/useNavigationStatus';

type HeaderButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

const HeaderButton: FC<HeaderButtonProps> = ({
  children,
  onClick,
  className,
  ...props
}) => (
  <Button
    {...props}
    className={cx(
      'lg:hidden inline-flex items-center justify-center p-6 rounded-md text-gray-600 hover:text-black focus:text-black dark:text-gray-400 dark:hover:text-white dark-focus:text-white focus:outline-none transition duration-300 ease-in-out',
      className
    )}
    variantType={ButtonTypes.Nude}
    type="button"
    onClick={onClick}
  >
    {children}
  </Button>
);

type Props = {
  left: ReactNode;
  center: ReactNode;
  right: ReactNode;
  fillEmptySpace?: boolean;
  isTransparent: boolean;
  openLabel?: string;
  closeLabel?: string;
  navClassName?: string;
  fixed?: boolean;
  backgroundVariant?: HeaderBackgroundVariant;
};

export enum HeaderBackgroundVariant {
  MAIN = 'dark:bg-black bg-white',
  SECOND = 'bg-lighter-1 dark:bg-darker-1',
}

const Header: React.FC<Props> = ({
  left = null,
  center = null,
  right = null,
  fillEmptySpace = true,
  isTransparent,
  openLabel,
  closeLabel,
  navClassName,
  fixed = true,
  backgroundVariant,
}) => {
  const scrolledStatus = useNavigationStatus();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const onClickMenu = useCallback(() => {
    setShowMobileMenu(!showMobileMenu);
  }, [showMobileMenu]);

  const transparentMode = isTransparent && !scrolledStatus && !showMobileMenu;

  return (
    <div
      className={cx('bg-white dark:bg-black', {
        'mode-dark': transparentMode,
        'Header__padding-size': fillEmptySpace && !backgroundVariant,
      })}
    >
      <nav
        className={cx(
          navClassName,
          'w-full top-0 left-0 right-0 z-50',
          {
            'Header__background ': !transparentMode && !backgroundVariant,
            'Header__transparent ': transparentMode,
            'Header__background-size ': !transparentMode && !showMobileMenu,
            'Header__transparent-size ': transparentMode && !showMobileMenu,
            'Header__common ': isTransparent,
            fixed: fixed,
          },
          !transparentMode && backgroundVariant !== undefined
            ? backgroundVariant
            : undefined
        )}
      >
        <div
          className={cx(
            'Header__common max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 h-full justify-center',
            {
              'Header__background-size ': !transparentMode,
              'Header__transparent-size ': transparentMode,
            }
          )}
          data-testid="header-desktop"
        >
          <div className="flex justify-between items-center h-16 py-6 lg:justify-start lg:space-x-10 h-full">
            <div className="lg:w-0 lg:flex-1">{left}</div>
            <div className="-mr-2 -my-2">
              <HeaderButton
                className={cx({
                  block: !showMobileMenu,
                  hidden: showMobileMenu,
                })}
                onClick={onClickMenu}
                aria-label={openLabel}
              >
                <BurgerMenuIcon className="h-6 w-6" />
              </HeaderButton>
              <HeaderButton
                className={cx({
                  block: showMobileMenu,
                  hidden: !showMobileMenu,
                })}
                onClick={onClickMenu}
                aria-label={closeLabel}
              >
                <CloseIcon className="h-6 w-6" />
              </HeaderButton>
            </div>
            <nav className="hidden lg:flex space-x-10">{center}</nav>
            <div className="hidden lg:flex items-center justify-end space-x-8 lg:flex-1 lg:w-0">
              {right}
            </div>
          </div>
        </div>

        <div
          className={cx('Header__animate-all lg:hidden h-screen', {
            'h-0 invisible opacity-0': !showMobileMenu,
            'h-auto visible opacity-100': showMobileMenu,
          })}
          data-testid="header-mobile"
        >
          <div className="pt-5 pb-6 px-5 space-y-6">
            <div>
              <nav className="grid gap-y-8">{center}</nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">{right}</div>
          </div>
          {/*<div className="px-2 pt-2 pb-3">{center}</div>*/}
          {/*<div className="px-2 pt-2 pb-3">{right}</div>*/}
        </div>
      </nav>
    </div>
  );

  return (
    <div className="relative flex items-center justify-between h-16">
      <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        {/* Mobile menu button*/}
        <button
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
          aria-label="Main menu"
          aria-expanded="false"
        >
          {/* Icon when menu is closed. */}
          {/* Menu open: "hidden", Menu closed: "block" */}
          <svg
            className="block h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          {/* Icon when menu is open. */}
          {/* Menu open: "block", Menu closed: "hidden" */}
          <svg
            className="hidden h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex-shrink-0">{left}</div>
        <div className="hidden sm:block sm:ml-6">
          <div className="flex">{center}</div>
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        {right}
      </div>
    </div>
  );

  return (
    <div className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 lg:justify-start lg:space-x-10">
          <div className="lg:w-0 lg:flex-1">{left}</div>
          <div className="-mr-2 -my-2 lg:hidden">
            <Button
              variantType={ButtonTypes.Secondary}
              type="button"
              onClick={() => setShowMobileMenu(true)}
            >
              <BurgerMenuIcon />
            </Button>
          </div>
          <nav className="hidden lg:flex space-x-10">{center}</nav>
          <div className="hidden lg:flex items-center justify-end space-x-8 lg:flex-1 lg:w-0">
            {right}
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div
        className={cx(
          'absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden',
          { hidden: !showMobileMenu }
        )}
      >
        <div className="rounded-lg shadow-lg">
          <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 space-y-6">
              <div className="flex items-center justify-between">
                <>
                  {left}
                  <div className="-mr-2">
                    <Button
                      onClick={() => setShowMobileMenu(false)}
                      variantType={ButtonTypes.Secondary}
                      type="button"
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                </>
              </div>

              <div>
                <nav className="grid gap-y-8">{center}</nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">{right}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
