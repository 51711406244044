import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { ContentType, Image } from './types';
import { generateSeo } from './generate';
import { LOCALES } from './locales';
import { useSitemetadata } from './query';

type Props = {
  description?: string;
  lang: string;
  title: string;
  path: string;
  meta?: never[];
  date?: string;
  image?: Image;
};

const PageSEO: FC<Props> = ({
  description = '',
  lang = 'fr',
  path,
  title,
  date,
  image,
}) => {
  const siteMetadata = useSitemetadata();
  const url = `${siteMetadata.siteUrl}${path}`;
  const { meta: generatedMeta, metaImage } = generateSeo({
    siteMetadata,
    description,
    locale: LOCALES[lang],
    url,
    title,
    type: ContentType.PAGE,
    date,
    image,
    lang,
  });

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${siteMetadata.title}`}
      meta={generatedMeta}
    >
      <link rel="image_src" href={metaImage?.url} />
    </Helmet>
  );
};

export default PageSEO;
