import React, { FC, useState } from 'react';
import cx from 'classnames';

type ItemProps = {
  selected: boolean;
};

const Item: FC<ItemProps> = ({ selected = false, children }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  return (
    <li
      role="option"
      onMouseEnter={() => setIsMouseEnter(true)}
      onMouseLeave={() => setIsMouseEnter(false)}
      className={cx(
        'cursor-default select-none relative py-2 pl-3 pr-9 font-secondary',
        {
          'text-white bg-br-1-600': isMouseEnter,
          'text-gray-900 dark:text-white': !isMouseEnter,
        }
      )}
    >
      <div className="flex items-center space-x-3">{children}</div>

      {selected && (
        <span
          className={cx('absolute inset-y-0 right-0 flex items-center pr-4', {
            'text-white': isMouseEnter,
            'text-br-1-600': !isMouseEnter,
          })}
        >
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      )}
    </li>
  );
};

type SelectedItemProps = {
  sign: boolean;
  className?: string;
  [key: string]: any;
};

const SelectedItem: FC<SelectedItemProps> = ({
  sign,
  children,
  className,
  ...props
}) => {
  return (
    <span className={cx('inline-block w-full rounded-md shadow-sm', className)}>
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        className="cursor-default relative w-full rounded-md border border-gray-300 bg-white dark:border-gray-900 dark:bg-darker-2 pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
        {...props}
      >
        <div className="flex items-center space-x-3 font-secondary text-gray-900 dark:text-white">
          {children}
        </div>
        {sign && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
            >
              <path
                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        )}
      </button>
    </span>
  );
};

type ListProps = {
  isOpen: boolean;
  className?: string;
};

const List: FC<ListProps> = ({ children, isOpen, className = '' }) => (
  <div
    className={cx(
      'absolute mt-1 w-full rounded-md bg-white dark:bg-darker-2 shadow-lg',
      {
        hidden: !isOpen,
        [className]: isOpen,
      }
    )}
  >
    <ul
      tabIndex={-1}
      role="listbox"
      aria-labelledby="listbox-label"
      aria-activedescendant="listbox-item-3"
      className="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
    >
      {children}
    </ul>
  </div>
);

const Dropdown: FC & {
  Item: FC<ItemProps>;
  SelectedItem: FC<SelectedItemProps>;
  List: FC<ListProps>;
} = ({ children, ...props }) => {
  return (
    <div className="relative" {...props}>
      {children}
    </div>
  );
};

Dropdown.Item = Item;
Dropdown.SelectedItem = SelectedItem;
Dropdown.List = List;

export default Dropdown;
