import { graphql, useStaticQuery } from 'gatsby';
import { SiteMetadata } from './types';

export const useSitemetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            logo
            name
            title
            description
            author
            siteUrl
            og {
              image {
                width
                height
                url
              }
            }
            googleSiteVerification
            social {
              facebookPageUrl
              facebookAppId
              twitterPageUrl
            }
          }
        }
      }
    `
  );

  return site.siteMetadata as SiteMetadata;
};
