import React, { Attributes, ElementType } from 'react';
import cx from 'classnames';

export const LinkActiveClass = 'Link__active';

type Props = {
  as?: ElementType;
  className?: string;
  props?: Attributes | null;
  href?: string;
  isExternal?: boolean;
  defaultStyle?: boolean;
  [key: string]: any;
};

const Link: React.FC<Props> = ({
  as = 'a',
  className = '',
  isExternal = false,
  defaultStyle = true,
  ...props
}) => {
  const target = '_blank';
  const rel = 'noopener noreferrer';

  return React.createElement(as, {
    ...props,
    target: isExternal ? target : undefined,
    rel: isExternal ? rel : undefined,
    className: cx(
      'text-base leading-6 font-medium focus:outline-none transition ease-in-out duration-150 font-secondary ',
      {
        'text-black hover:text-gray-700 focus:text-gray-700 active:text-br-1-600': defaultStyle,
        'dark:text-gray-400 dark:hover:text-white dark-focus:text-white dark:active:text-br-1-600': defaultStyle,
      },
      className
    ),
  });
};

export default Link;
