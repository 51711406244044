import React, { FC } from 'react';
import Text from '@kastle/ui/src/atoms/Text/Text';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import { useTranslation } from 'react-i18next';
import {
  ContentRow,
  Head,
  Separator,
} from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import { MdLocationOn, MdMail, MdPhone } from 'react-icons/md';
import Link from '@kastle/ui/src/atoms/Link/Link';

const Contact: FC = () => {
  const { t } = useTranslation();

  return (
    <Section type={SectionType.Primary}>
      <ContentRow>
        <Separator>
          <Head>
            <MdPhone className="Icon__base" />
            <Link
              isExternal
              href={t('common.contact.phone.action')}
              aria-label={t('common.contact.phone.name')}
            >
              <Text>{t('common.contact.phone.text')}</Text>
            </Link>
            <Text>{t('common.contact.availability')}</Text>
          </Head>
        </Separator>
        <Separator>
          <Head>
            <MdMail className="Icon__base" />
            <Link
              isExternal
              href={t('common.contact.mail.action')}
              aria-label={t('common.contact.mail.name')}
            >
              <Text>{t('common.contact.mail.text')}</Text>
            </Link>
          </Head>
        </Separator>
        <Separator>
          <Head>
            <MdLocationOn className="Icon__base" />
            <Link
              isExternal
              href={t('common.contact.location.action')}
              aria-label={t('common.contact.location.name')}
            >
              <Text>{t('common.contact.location.text')}</Text>
            </Link>
            <Text>{t('common.contact.location.detail')}</Text>
          </Head>
        </Separator>
      </ContentRow>
    </Section>
  );
};
export default Contact;
