import React, { FC, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeClassName } from '@kastle/ui/src/theme';
import { ThemeTypes } from './Theme.types';

type ThemeContext = {
  theme: ThemeTypes;
  setTheme: (theme: ThemeTypes) => void;
};

export const ThemeContext = React.createContext({
  theme: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {},
});

export const useTheme = (): ThemeContext => {
  return useContext(ThemeContext);
};

type Props = {
  lightColor: string;
  darkColor: string;
  defaultTheme: ThemeTypes;
};

const ThemeContextProvider: FC<Props> = ({
  children,
  lightColor,
  darkColor,
  defaultTheme = ThemeTypes.DARK,
}) => {
  const isSSR = typeof window === 'undefined';
  const [theme, setTheme] = useState(
    isSSR ? defaultTheme : localStorage.getItem('theme') || defaultTheme
  );

  useEffect(() => {
    if (theme === ThemeTypes.DARK) {
      document.body.classList.remove(ThemeClassName.WHITE);
      document.body.classList.add(ThemeClassName.DARK);
    } else {
      document.body.classList.remove(ThemeClassName.DARK);
      document.body.classList.add(ThemeClassName.WHITE);
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
      <Helmet>
        <meta
          name="theme-color"
          content={theme === ThemeTypes.DARK ? darkColor : lightColor}
        />
      </Helmet>
      <div className={theme === ThemeTypes.DARK ? 'mode-dark' : ''}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
