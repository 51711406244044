import React, { FC } from 'react';

type LanguageItemProps = {
  name?: string;
  Icon: FC<{ [key: string]: any }>;
};

export const LanguageItem: FC<LanguageItemProps> = ({ name = '', Icon }) => (
  <>
    <Icon className="flex-shrink-0 h-6 w-6 rounded-full" />
    <span className="block truncate">{name}</span>
  </>
);
