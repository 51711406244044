import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PKG from '../../../package.json';

import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import Icon from '@kastle/ui/src/atoms/Icons/Icon';
import { ChateauDeMoreyPrimary } from '@kastle/ui/src/atoms/Logos/ChateauDeMorey';
import Footer from '@kastle/ui/src/organisms/Footer/Footer';
import Link from '@kastle/ui/src/atoms/Link/Link';
import Text from '@kastle/ui/src/atoms/Text/Text';
import ArticleCard from '@kastle/ui/src/molecules/ArticleCard/ArticleCard';
import InternalLink from '../../../../components/src/components/Link/InternalLink';
import LanguagesComponent from '../Language/Language';
import { PageContext } from '../../types';
import {
  ContentWrap,
  Separator,
  SmallSeparator,
} from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import {
  FaFacebook,
  FaFacebookMessenger,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import Contact from '../Contact/Contact';

type Props = {
  pageContext: PageContext;
};

const FooterComponent: FC<Props> = ({ pageContext }) => {
  const { t } = useTranslation();

  return (
    <>
      <Section type={SectionType.Primary}>
        <Contact />
      </Section>
      <Section type={SectionType.Primary}>
        <ContentWrap>
          <Separator>
            <Link
              isExternal
              href={t('common.contact.facebook')}
              aria-label="Facebook"
              data-testid="facebook-link"
            >
              <FaFacebook className="Icon__base" />
            </Link>
          </Separator>

          <Separator>
            <Link
              isExternal
              href={t('common.contact.messenger')}
              aria-label="Messenger"
              data-testid="messenger-link"
            >
              <FaFacebookMessenger className="Icon__base" />
            </Link>
          </Separator>
          <Separator>
            <Link
              isExternal
              href={t('common.contact.instagram')}
              aria-label="Instagram"
              data-testid="instagram-link"
            >
              <FaInstagram className="Icon__base" />
            </Link>
          </Separator>
          <Separator>
            <Link
              isExternal
              href={t('common.contact.twitter')}
              aria-label="Twitter"
              data-testid="twitter-link"
            >
              <FaTwitter className="Icon__base" />
            </Link>
          </Separator>
          <Separator>
            <Link
              isExternal
              href={t('common.contact.linkedin')}
              aria-label="LinkedIn"
              data-testid="linkedIn-link"
            >
              <FaLinkedinIn className="Icon__base" />
            </Link>
          </Separator>
        </ContentWrap>
      </Section>
      <Section type={SectionType.Primary}>
        <ArticleCard />
        <Icon Component={ChateauDeMoreyPrimary} className="Logo__base-size" />
        <Footer>
          <Footer.Column>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.home.link')}
                aria-label={t('common.nav.home.name')}
                data-testid="home-link"
              >
                {t('common.nav.home.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.blog.link')}
                aria-label={t('common.nav.blog.name')}
                data-testid="blog-link"
              >
                {t('common.nav.blog.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <Link
                href={t('common.nav.book.link')}
                isExternal
                aria-label={t('common.nav.book.name')}
                data-testid="book-link"
              >
                {t('common.nav.book.name')}
              </Link>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.bnb.link')}
                aria-label={t('common.nav.bnb.name')}
                data-testid="bnb-link"
              >
                {t('common.nav.bnb.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.newsletter.link')}
                aria-label={t('common.nav.newsletter.name')}
                data-testid="newsletter-link"
              >
                {t('common.nav.newsletter.name')}
              </InternalLink>
            </SmallSeparator>
          </Footer.Column>
          <Footer.Column>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.spaceRental.link')}
                aria-label={t('common.nav.spaceRental.name')}
                data-testid="spaceRental-link"
              >
                {t('common.nav.spaceRental.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.castle.link')}
                aria-label={t('common.nav.castle.name')}
                data-testid="castle-link"
              >
                {t('common.nav.castle.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.contact.link')}
                aria-label={t('common.nav.contact.name')}
                data-testid="contact-link"
              >
                {t('common.nav.contact.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.legal.link')}
                aria-label={t('common.nav.legal.name')}
                data-testid="legal-link"
              >
                {t('common.nav.legal.name')}
              </InternalLink>
            </SmallSeparator>
          </Footer.Column>
          <Footer.Column>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.hobby.link')}
                aria-label={t('common.nav.hobby.name')}
                data-testid="hobby-link"
              >
                {t('common.nav.hobby.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.cgv.link')}
                aria-label={t('common.nav.cgv.name')}
                data-testid="cgv-link"
              >
                {t('common.nav.cgv.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <InternalLink
                to={t('common.nav.partners.link')}
                aria-label={t('common.nav.partners.name')}
                data-testid="partners-link"
              >
                {t('common.nav.partners.name')}
              </InternalLink>
            </SmallSeparator>
            <SmallSeparator>
              <Link
                href={t('common.nav.shop.link')}
                isExternal
                aria-label={t('common.nav.shop.name')}
                data-testid="shop-link"
              >
                {t('common.nav.shop.name')}
              </Link>
            </SmallSeparator>
          </Footer.Column>
          <Footer.Column>
            <LanguagesComponent currentLang={pageContext.langKey} />
          </Footer.Column>
        </Footer>
      </Section>
      <Section type={SectionType.Primary}>
        <Text>Made with ❤ in Nancy, Lorraine</Text>
        <Link
          href="https://julienkarst.com"
          isExternal
          aria-label="Karst Development Software"
          data-testid="karstdevelopment-link"
        >
          by Karst Development SARL
        </Link>
        <Text>{`Version ${PKG.version}`}</Text>
      </Section>
      <Section type={SectionType.Primary}>
        <Text>{`Since XII century`}</Text>
        <Text>{`Copyright © 1995-${new Date().getFullYear()}`}</Text>
      </Section>
    </>
  );
};

export default FooterComponent;
