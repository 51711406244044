import React, { ElementType, FC } from 'react';
import { HeadingTypes } from './atoms/Heading/Heading.types';
import Heading from './atoms/Heading/Heading';
import Link from './atoms/Link/Link';
import Text from './atoms/Text/Text';

const getTag = (Tag: ElementType, className: string): FC => {
  const TagComponent: React.FC = ({ children, ...props }) => (
    <Tag {...props} className={className}>
      {children}
    </Tag>
  );

  return TagComponent;
};

const getHeading = (type: HeadingTypes): FC => {
  const HeadingComponent: React.FC = ({ children, ...props }) => (
    <Heading as={type} {...props} className="mt-8 mb-4 -ml-1">
      {children}
    </Heading>
  );
  return HeadingComponent;
};

const Paragraph: FC = ({ children }) => (
  <Text className="mt-4 w-full">{children}</Text>
);

const List: FC = ({ children }) => (
  <ul className="text-black dark:text-white list-disc font-secondary ml-4 mt-2">
    {children}
  </ul>
);

const ListItem: FC = ({ children }) => (
  <li className="text-black dark:text-white font-secondary my-1">{children}</li>
);

export const HTMLElements = {
  h1: getHeading(HeadingTypes.h1),
  h2: getHeading(HeadingTypes.h2),
  h3: getHeading(HeadingTypes.h3),
  h4: getHeading(HeadingTypes.h4),
  h5: getHeading(HeadingTypes.h5),
  h6: getHeading(HeadingTypes.h6),
  p: Paragraph,
  a: Link,
  blockquote: getTag(
    'blockquote',
    'text-black dark:text-white border-solid border-l-4 border-gray-300 dark:border-gray-800 pl-4'
  ),
  ul: List,
  ol: getTag(
    'ol',
    'text-black dark:text-white list-decimal font-secondary mt-2'
  ),
  li: ListItem,
  strong: getTag('strong', 'font-semibold'),
  em: getTag('em', 'italic'),
};
