import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Header, {
  HeaderBackgroundVariant,
} from '@kastle/ui/src/organisms/Header/Header';
import Icon from '@kastle/ui/src/atoms/Icons/Icon';
import { ChateauDeMoreyPrimary } from '@kastle/ui/src/atoms/Logos/ChateauDeMorey';
import Button from '@kastle/ui/src/atoms/Button/Button';
import { ButtonTypes } from '@kastle/ui/src/atoms/Button/Button.types';
import InternalLink from '@kastle/components/src/components/Link/InternalLink';
import { PageContext } from '../../types';
import { ThemeTypes } from '../../../../components/src/components/Theme/Theme.types';
import { useTheme } from '../../../../components/src/components/Theme/Theme';
import { FiMoon, FiSun } from 'react-icons/fi';

type Props = {
  pageContext: PageContext;
  fillEmptySpace: boolean;
  transparentMode: boolean;
};

const HeaderComponent: FC<Props> = ({ fillEmptySpace, transparentMode }) => {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();

  const switchTheme = useCallback(
    () =>
      setTheme(theme === ThemeTypes.DARK ? ThemeTypes.LIGHT : ThemeTypes.DARK),
    [theme]
  );

  return (
    <Header
      fillEmptySpace={fillEmptySpace}
      left={
        <Link
          to={t('common.nav.home.link')}
          aria-label={t('common.nav.home.name')}
          className="flex flex-1"
        >
          <Icon
            Component={ChateauDeMoreyPrimary}
            className="flex h-16 w-auto text-black dark:text-white"
          />
        </Link>
      }
      center={
        <>
          <InternalLink
            to={t('common.nav.bnb.link')}
            aria-label={t('common.nav.bnb.name')}
            data-testid="bnb-link"
          >
            {t('common.nav.bnb.name')}
          </InternalLink>
          <InternalLink
            to={t('common.nav.spaceRental.link')}
            aria-label={t('common.nav.spaceRental.name')}
            data-testid="spaceRental-link"
          >
            {t('common.nav.spaceRental.name')}
          </InternalLink>
          <InternalLink
            to={t('common.nav.blog.link')}
            aria-label={t('common.nav.blog.name')}
            data-testid="blog-link"
          >
            {t('common.nav.blog.name')}
          </InternalLink>
          <InternalLink
            to={t('common.nav.contact.link')}
            aria-label={t('common.nav.contact.name')}
            data-testid="contact-link"
          >
            {t('common.nav.contact.name')}
          </InternalLink>
        </>
      }
      isTransparent={false}
      fixed={false}
      backgroundVariant={
        !transparentMode ? HeaderBackgroundVariant.SECOND : undefined
      }
      right={
        <>
          <Button
            as={Link}
            to={t('common.nav.book.link')}
            data-testid="book-link"
            aria-label={t('common.nav.book.name')}
          >
            {t('common.nav.book.name')}
          </Button>
          <Button
            variantType={ButtonTypes.Nude}
            onClick={switchTheme}
            aria-label={t('common.nav.theme.switch')}
            data-testid="theme-button"
          >
            {theme === ThemeTypes.DARK ? (
              <FiSun className="flex-shrink-0 h-6 w-6" />
            ) : (
              <FiMoon className="flex-shrink-0 h-6 w-6" />
            )}
          </Button>
        </>
      }
      openLabel={t('common.nav.menu.open')}
      closeLabel={t('common.nav.menu.close')}
    />
  );
};

export default HeaderComponent;
