import CommonNl from '../translations/common.nl.json';
import HomeNl from '../translations/home.nl.json';

import CommonDe from '../translations/common.de.json';
import HomeDe from '../translations/home.de.json';

import CommonEn from '../translations/common.en.json';
import HomeEn from '../translations/home.en.json';

import CommonFr from '../translations/common.fr.json';
import HomeFr from '../translations/home.fr.json';
import ContentsFr from '../translations/contents.fr.json';

const resources = {
  en: {
    translation: {
      common: CommonEn,
      home: HomeEn,
    },
  },
  de: {
    translation: {
      common: CommonDe,
      home: HomeDe,
    },
  },
  nl: {
    translation: {
      common: CommonNl,
      home: HomeNl,
    },
  },
  fr: {
    translation: {
      common: CommonFr,
      home: HomeFr,
      contents: ContentsFr,
    },
  },
};

export default resources;
