import React, { FC } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { HTMLElements } from '@kastle/ui/src/HTMLElements';
import ButtonGroup from '@kastle/ui/src/atoms/Button/ButtonGroup';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@kastle/ui/src/atoms/Link/Link';
import Button from '@kastle/ui/src/atoms/Button/Button';
import FullImage from '@kastle/components/src/components/Image/FullImage';

const LinkTypes = {
  booking: 'https://chateau-de-morey.amenitiz.io/de/booking/room',
};

const Form = React.lazy(() => import('../Form/Form'));

const MdxRenderer: FC = ({ children }) => (
  <MDXProvider
    components={{
      ...HTMLElements,
      // eslint-disable-next-line react/display-name,@typescript-eslint/explicit-function-return-type
      Form: ({ url }) => {
        const isSSR = typeof window === 'undefined';
        return (
          <>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <Form url={url} />
              </React.Suspense>
            )}
          </>
        );
      },
      // eslint-disable-next-line react/display-name,@typescript-eslint/explicit-function-return-type
      ButtonInternal: ({ to, children, ...props }) => {
        const label = typeof children === 'string' ? children : undefined;

        return (
          <ButtonGroup>
            <Link
              as={GatsbyLink}
              to={to}
              style={{ textDecoration: 'none' }}
              aria-label={label}
              {...props}
            >
              <Button aria-label={label} {...props}>
                {children}
              </Button>
            </Link>
          </ButtonGroup>
        );
      },
      // eslint-disable-next-line react/display-name,@typescript-eslint/explicit-function-return-type
      ButtonExternal: ({ href, type, children, ...props }) => {
        const label = typeof children === 'string' ? children : undefined;
        return (
          <ButtonGroup>
            <Link
              href={LinkTypes[type] || href}
              isExternal
              style={{ textDecoration: 'none' }}
              aria-label={label}
              {...props}
            >
              <Button aria-label={label} {...props}>
                {children}
              </Button>
            </Link>
          </ButtonGroup>
        );
      },
      FullImage,
    }}
  >
    {children}
  </MDXProvider>
);

export default MdxRenderer;
