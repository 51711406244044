export type Image = {
  url: string;
  width: string;
  height: string;
};

export type SiteMetadata = {
  logo: string;
  name: string;
  title: string;
  description: string;
  author: string;
  siteUrl: string;
  social: {
    facebookPageUrl: string;
    facebookAppId: string;
    twitterPageUrl: string;
  };
  og: {
    image: Image;
  };
  googleSiteVerification: string;
};

export enum ContentType {
  PAGE = 'website',
  ARTICLE = 'article',
}
