import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import resources from './ressources';

const i18n = i18next.createInstance();

export const defaultLocale = 'fr';

export const options = {
  fallbackLng: defaultLocale,
  resources,

  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  //
  // react: {
  //   wait: false,
  // },

  // initImmediate: false, // Important for SSR to work
};

type Props = {
  pageContext: {
    langKey: string;
  };
};

const withI18next = <P extends Record<string, never>>(
  Comp: React.ComponentType<P>
): React.ComponentType<P> => {
  class I18nHOC extends React.Component {
    props: Props;

    constructor(props: Props) {
      super(props);
      i18n.init({
        ...options,
        lng: this.props.pageContext.langKey,
        // react: {
        //   useSuspense: false,
        // },
      });
      this.activateLng(this.props.pageContext.langKey);
    }

    componentDidUpdate(prevProps): void {
      if (this.props.pageContext.langKey !== prevProps.pageContext.langKey) {
        this.activateLng(this.props.pageContext.langKey);
      }
    }

    activateLng(locale): void {
      i18n.changeLanguage(locale);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    render() {
      return (
        <I18nextProvider i18n={i18n}>
          <Comp {...this.props} />
        </I18nextProvider>
      );
    }
  }

  return I18nHOC;
};

export default withI18next;
