import React, { FC } from 'react';
import cx from 'classnames';

type Props = { className?: string };

const Footer: FC<Props> & { Column: FC<Props> } = ({ children, className }) => {
  return (
    <footer
      className={cx(
        'p-10 sm:p-20 flex md:flex justify-around text-white flex-col lg:flex-row align-center w-full',
        className
      )}
      data-testid="footer"
    >
      {children}
    </footer>
  );
};

const FooterColumn: FC<Props> = ({ children, className }) => {
  return <div className={cx('mb-4 flex flex-col', className)}>{children}</div>;
};

Footer.Column = FooterColumn;

export default Footer;
