import React, { FC } from 'react';
import { Link } from 'gatsby';
import LanguagesDropdown from '@kastle/ui/src/atoms/Dropdown/Language';
import FranceIcon from '@kastle/ui/src/atoms/Flags/France';
import UnitedKingdomIcon from '@kastle/ui/src/atoms/Flags/UnitedKingdom';
import NetherlandsIcon from '@kastle/ui/src/atoms/Flags/Netherlands';
import GermanyIcon from '@kastle/ui/src/atoms/Flags/Germany';
import { useTranslation } from 'react-i18next';

export enum Languages {
  FR = 'fr',
  EN = 'en',
  DE = 'de',
  NL = 'nl',
}

const AvailableLanguages = {
  [Languages.FR]: {
    lang: Languages.FR,
    Icon: FranceIcon,
    path: '/',
  },
  [Languages.EN]: {
    lang: Languages.EN,
    Icon: UnitedKingdomIcon,
    path: '/en',
  },
  [Languages.DE]: {
    lang: Languages.DE,
    Icon: GermanyIcon,
    path: '/de',
  },
  [Languages.NL]: {
    lang: Languages.NL,
    Icon: NetherlandsIcon,
    path: '/nl',
  },
};

type Props = {
  currentLang: Languages;
};

const LanguagesComponent: FC<Props> = ({ currentLang }) => {
  const { t } = useTranslation();
  return (
    <LanguagesDropdown
      current={AvailableLanguages[currentLang]}
      currentLabel={`${t('common.languages.current')} ${t(
        `common.languages.list.${currentLang}`
      )}`}
      data-testid="language-dropdown"
    >
      {Object.values(AvailableLanguages).map((item) => (
        <Link
          to={item.path}
          key={item.path}
          aria-label={t(`common.languages.list.${item.lang}`)}
          data-testid={`language-${item.lang}`}
        >
          <LanguagesDropdown.Item {...item} />
        </Link>
      ))}
    </LanguagesDropdown>
  );
};

export default LanguagesComponent;
