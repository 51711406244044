import React, { FC } from 'react';
import { Link } from 'gatsby';
import LinkComponent, { LinkActiveClass } from '@kastle/ui/src/atoms/Link/Link';

type Props = {
  to: string;
  activeClassName?: string;
  className?: string;
};

const InternalLink: FC<Props> = ({
  children,
  to,
  activeClassName = '',
  className = '',
  ...others
}) => (
  <LinkComponent
    {...others}
    as={Link}
    to={to}
    activeClassName={`${LinkActiveClass} ${activeClassName}`}
    className={className}
  >
    {children}
  </LinkComponent>
);

export default InternalLink;
