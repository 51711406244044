import React, { FC } from 'react';

// From: https://www.flaticon.com/packs/countrys-flags
const FranceIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    enableBackground="new 0 0 512 512"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
    <path
      d="m512 256c0-110.071-69.472-203.906-166.957-240.077v480.155c97.485-36.172 166.957-130.007 166.957-240.078z"
      fill="#d80027"
    />
    <path
      d="m0 256c0 110.071 69.473 203.906 166.957 240.077v-480.154c-97.484 36.171-166.957 130.006-166.957 240.077z"
      fill="#0052b4"
    />
  </svg>
);

export default FranceIcon;
