import { SiteMetadata, ContentType, Image } from './types';

type Props = {
  siteMetadata: SiteMetadata;
  description?: string;
  lang: string;
  locale: string;
  title: string;
  url: string;
  type: ContentType;
  date?: string;
  categories?: string[];
  tags?: string[];
  image?: Image;
};

type Output = {
  meta: never[];
  generatedTitle: string;
  metaImage: Image;
  metaDescription: string;
};

export const generateSeo = ({
  siteMetadata,
  description = '',
  locale,
  url,
  title,
  type,
  date,
  image: contentImage,
  tags,
  categories,
}: Props): Output => {
  const generatedTitle = title
    ? `${title} - ${siteMetadata.title}`
    : siteMetadata.title;

  const image = {
    url: `${siteMetadata.siteUrl}${
      contentImage?.url || siteMetadata.og.image.url
    }`,
    width: contentImage?.width || siteMetadata.og.image.width,
    height: contentImage?.height || siteMetadata.og.image.height,
  };
  const metaDescription = description || siteMetadata.description;

  const metaTags = tags
    ? tags.map((tag) => ({
        property: 'article:tag',
        content: tag,
      }))
    : [];
  const metaSections = categories
    ? categories.map((section) => ({
        property: 'article:section',
        content: section,
      }))
    : [];

  const articleMeta = [
    ...metaTags,
    ...metaSections,
    {
      property: 'article:author',
      content: siteMetadata.social.facebookPageUrl,
    },
    {
      property: 'article:publisher',
      content: siteMetadata.social.facebookPageUrl,
    },
    {
      property: 'article:published_time',
      content: date,
    },
    {
      property: 'og:datePublished',
      content: date,
    },
  ];

  const meta = [
    ...(type === ContentType.ARTICLE ? articleMeta : []),
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `author`,
      content: siteMetadata.author,
    },
    {
      name: `robots`,
      content: 'index,follow',
    },
    {
      property: `og:locale`,
      content: locale,
    },
    {
      property: `og:title`,
      content: generatedTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: type,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:publisher`,
      content: siteMetadata.author,
    },
    {
      property: `og:article:author`,
      content: siteMetadata.author,
    },
    {
      property: `og:site_name`,
      content: siteMetadata.title,
    },
    {
      property: `og:image`,
      content: image.url,
    },
    {
      property: `og:image:width`,
      content: image.width,
    },
    {
      property: `og:image:height`,
      content: image.height,
    },
    {
      property: `fb:pages`,
      content: siteMetadata.social.facebookAppId,
    },
    {
      property: `fb:app_id`,
      content: siteMetadata.social.facebookAppId,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.social.twitterPageUrl,
    },
    {
      name: `twitter:site`,
      content: siteMetadata.social.twitterPageUrl,
    },
    {
      name: `twitter:title`,
      content: generatedTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: image.url,
    },
    {
      property: `google-site-verification`,
      content: siteMetadata.googleSiteVerification,
    },
    {
      property: `mobile-web-app-capable`,
      content: 'yes',
    },
  ];

  return { meta, generatedTitle, metaImage: image, metaDescription };
};
